import { useContext, useEffect, useState } from "react";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from 'react-router-dom';
import discord_img from "./images/discord.png";
import twitter_img from "./images/twitter.png";
import pops_1 from "./images/pops_1.png";
import pops_2 from "./images/pops_2.png";
import pops_3 from "./images/pops_3.png";
import pops_4 from "./images/pops_4.png";
import pops_5 from "./images/pops_5.png";
import pops_6 from "./images/pops_6.png";
import pops_7 from "./images/pops_7.png";
import pops_8 from "./images/pops_8.png";
import menu_img from "./images/menu.png";
import gallery from "./images/gallery.png";
import nftkey from "./images/nftkey.png";
import mace from "./images/mace.png";
import funeral from "./images/funeral.png";
import logo from "./images/logo.png";
import gallerylogo from "./images/logo.png";
import mm_logo from "./images/metamask-logo.png";
import { POPSKULLY_ADDRESS } from "./constants";
import popsAbi from '../src/contractABIs/popsABI.json';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Context } from "./Store";
import { useAccount, useContractEvent, useContractRead, useContractReads, useWaitForTransaction } from "wagmi";
import useMint from "./functions/useMint";
import useMintWhitelist from "./functions/useMintWhitelist";
import useCheckWhitelist from "./functions/useCheckWhitelist";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import NftModal from "./components/NftModal";
import { BigNumber } from "ethers";

library.add(faSpinner);

const menuToggle = () => {
    const navlinks = document.querySelector('.nav-links');
    navlinks?.classList.toggle('mobile-menu');
}
function App() {

  const [popSkullySupply, setPopSkullySupply] = useState(0);
  const [spendInput, setSpendInput] = useState('1');
  const [showModal, setShowModal] = useState(false);
  const [tokenIds, setTokenIds] = useState<Array<any>>([]);
  const { isConnected } = useAccount();

  const { data: totalSupply, error: supplyError, isError: supplyIsError, refetch: refetchSupply } = useContractRead({
    address: POPSKULLY_ADDRESS,
    abi: popsAbi,
    functionName: 'totalSupply',
    onSuccess() {
        console.log(totalSupply);
        setPopSkullySupply(totalSupply as number);
    }
  });

  const isWhitelisted = useCheckWhitelist();

  const { data: noWhitelistMintData, write: mintNoWhitelist, errorMessage: noWhitelistError, isError: noWhitelistIsError, isSuccess, mintWriteIsError } = useMint(spendInput);
  const { data: whitelistMintData, write: mintWhitelist, errorMessage: whitelistError, isError: whitelistIsError } = useMintWhitelist();

  const { isLoading: isWhitelistMintLoading, isSuccess: isWhitelistMintSucces } = useWaitForTransaction({
    hash: whitelistMintData?.hash,
    onSettled() {
        refetchSupply().then((res) => {
            console.log("result of refetch: ", res.data);
            setPopSkullySupply(res.data as number);
        })
     }
  });

  const { isLoading: isMintLoading, isSuccess: isMintSuccess } = useWaitForTransaction({
    hash: noWhitelistMintData?.hash,
     onSettled() {
        refetchSupply().then((res) => {
            console.log("result of refetch: ", res.data);
            setPopSkullySupply(res.data as number);
        })
     }
  });

  useEffect(() => {
    isMintSuccess && (
        alert(`You minted ${spendInput} Pop Skullys! Check it out at https://ftmscan.com/tx/${noWhitelistMintData?.hash}`)
    );
    isWhitelistMintSucces && (
        alert(`You minted ${spendInput} Pop Skullys! Check it out at https://ftmscan.com/tx/${whitelistMintData?.hash}`)
    );
  }, [isWhitelistMintSucces, isMintSuccess]);

  useEffect(() => {
    console.log(popSkullySupply);
  }, [popSkullySupply]);

    useContractEvent({
        address: POPSKULLY_ADDRESS,
        abi: popsAbi,
        eventName: 'Transfer',
        listener(...args) {
            console.log(args[2].toNumber());
            setTokenIds((prev) => [...prev, args[2].toNumber()]);
            console.log(tokenIds);
            setShowModal(true);
        },
    })

  return (
    <Router>
        <div className="App">
            <nav className="navbar">
                    <img className="logo" src={logo} alt=""/>
                    <ul className="nav-links">
                        <li><a href="https://skullys.shop">SHOP</a></li>
                        <li><a href="https://summoning.io">READ</a></li>
                        <li><Link to="/"><ConnectButton label="Connect" accountStatus="address" chainStatus="none" showBalance={false}/></Link></li>
                    </ul>
                    <img src={menu_img} alt="" className="menu-btn" onClick={menuToggle}/>
                </nav>
                <header>

                <div className="wrap">
                <div className="floatleft">
                <section className="mint-text">
        
                <h1>JOIN THE CULT</h1>
                
                        <h4>Mint a limited edition digital collectible.</h4>
                        <h3>50 FTM</h3>
                        <h2>{Intl.NumberFormat().format(popSkullySupply)}/{Intl.NumberFormat().format(3333)}</h2>
                        {
                            !isConnected && (<h4>Connect your wallet</h4>)
                        }
                        {
                            isConnected && isWhitelisted && (
                                <>
                                    <h4>You are on the list. Cast your spell for free.</h4>
                                    <br/>
                                    <button disabled={isWhitelistMintLoading || !mintWhitelist} onClick={() => mintWhitelist?.()}>{!isWhitelistMintLoading ? 'SUMMON' : <FontAwesomeIcon icon={faSpinner} color="white" spin/>}</button>
                                </>
                            )
                        }
                        {
                            isConnected && !isWhitelisted && (
                                <>
                                    <input type="number" value={spendInput} onInput={e => setSpendInput((e.target as HTMLInputElement).value)} placeholder="Amount: Max of 5"/>
                                    <br/>
                                    <br/>
                                    <button disabled={isMintLoading || !mintNoWhitelist} onClick={() => mintNoWhitelist?.()} >{!isMintLoading ? 'SUMMON' : <FontAwesomeIcon icon={faSpinner} color="white" spin/>}</button>
                                </>
                            )
                        }
        
            

                
                </section>



                    </div>
                    
                    <div className="floatright"></div>
                    <div className="clear: both;"></div>
                </div>

                <div>
                
                </div>
                </header>
                <section className="info">

                    <h1>3,333 Unique Digital COLLECTIBLES</h1>
                    <h4>Mint your own Pop Skully and pledge your allegiance to The Cult. These collectibles are randomly generated from traits designed to be use as digital identities.</h4>
                    <h4>Commemorate the life and legend of King Cold with these modern profile pictures. These collectibles are rare in nature and will only be available for a limited time.</h4>
                    <h4>View your Pop Skully on <a className="link" href="https://paintswap.finance/marketplace/fantom/collections/pop-skullys" target="_blank">PaintSwap</a> or <a href="https://nftkey.app/collections/popskullys/" target="_blank">NFTKey</a>.</h4>
                    
                </section>
                <section className="previews">
                    <div className="pops">
                    <img src={pops_1} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_2} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_3} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_4} alt=""/>
                    </div>
                </section>
                <section className="previews">
                    <div className="pops">
                    <img src={pops_5} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_8} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_7} alt=""/>
                    </div>
                    <div className="pops">
                    <img src={pops_6} alt=""/>
                    </div>
                </section>
            

            
            

                <section className="footer">
                    
                    <div className="social-links">
                        <a href="https://twitter.com/CultNFTs"><img src={twitter_img} alt=""/></a>
                        <a href="https://discord.gg/KRpT7UFgfB"><img src={discord_img} alt=""/></a>
                        <a href="https://nftkey.app/collections/popskullys/"><img src={nftkey} alt=""/></a>
                        <a href="https://fantomgallery.io"><img src={gallery} alt=""/></a>
                    </div>
                </section>
            </div>
            {
                showModal && (
                    <NftModal setShowModal={setShowModal} tokenIds={tokenIds} setTokenIds={setTokenIds} />
                )
            }
        </Router>
    );
}

export default App;
