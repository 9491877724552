import { BigNumber } from "ethers";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { POPSKULLY_ADDRESS, ZERO_ADDRESS } from "../constants";
import popsAbi from '../contractABIs/popsABI.json';

const useMintWhitelist = () => {
    let errorMessage;
    const { config, error, isError } = usePrepareContractWrite({
        address: POPSKULLY_ADDRESS,
        abi: popsAbi,
        functionName: 'mint',
        args: [ZERO_ADDRESS, 1],
        overrides: {
            value: 0,
            gasLimit: BigNumber.from(250000)
        }
    })
    const { data, write } = useContractWrite(config);
    if(error && error.stack) {
        console.log(error.stack);
        console.log(error.message);
        if(error.stack.includes('}') && !error.stack.includes('Error: ')) {
            let errorMessageTemp = error.stack.split('}')[0];
            let errorMessageTemp2 = errorMessageTemp.concat('}}');
            let errorMessageTemp3 = JSON.parse(errorMessageTemp2);
            errorMessage = errorMessageTemp3.data.message;
            console.log(errorMessage);
        }
        else {
            errorMessage = error.message;
        }
    } else {
        errorMessage = undefined;
    }
    return { data, write, errorMessage, isError };
}

export default useMintWhitelist;