import { BigNumber, ethers } from "ethers";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { POPSKULLY_ADDRESS, ZERO_ADDRESS } from "../constants";
import popsAbi from '../contractABIs/popsABI.json';
import useCheckWhitelist from "./useCheckWhitelist";

const useMint = (amountToMint: string) => {
    const isWhitelisted = useCheckWhitelist();
    const cost = 50;
    let errorMessage;
    let numMints: number = Number(amountToMint);
    if(isWhitelisted) {
        numMints--;
    }
    const totalPayout = cost * numMints;
    let totalGasLimit = 250000 + (100000 * numMints);
    const { config, error, isError } = usePrepareContractWrite({
        address: POPSKULLY_ADDRESS,
        abi: popsAbi,
        functionName: 'mint',
        args: [ZERO_ADDRESS, amountToMint],
        overrides: {
            value: ethers.utils.parseEther(totalPayout.toString()),
            gasLimit: BigNumber.from(totalGasLimit)
        },
        onSuccess() {
            console.log(config);
        }
    });

    const { data, write, error: writeError, isLoading, isSuccess, isError: mintWriteIsError } = useContractWrite({
        ...config,
        onSuccess() {
            console.log(data)
        },
        onError() {
            console.log(data);
            console.log(writeError);
        }
    });

    if(error && error.stack) {
        console.log(error.stack);
        console.log(error.message);
        if(error.stack.includes('}') && !error.stack.includes('[')) {
            let errorMessageTemp = error.stack.split('}')[0];
            let errorMessageTemp2 = errorMessageTemp.concat('}}');
            let errorMessageTemp3 = JSON.parse(errorMessageTemp2);
            errorMessage = errorMessageTemp3.data.message;
            console.log(errorMessage);
        }
        else {
            let tempErrorMessage = error.message.split('(')[0];
            errorMessage = tempErrorMessage;
        }
    } else {
        errorMessage = undefined;
    }

    return { data, write, errorMessage, isError, isLoading, isSuccess, mintWriteIsError };
}

export default useMint;