import { Dispatch, SetStateAction, useState } from "react";
import { useContractReads } from "wagmi";
import { POPSKULLY_ADDRESS } from "../constants";
import popsAbi from '../contractABIs/popsABI.json';
import '../styles/modal.css';

interface ModalProps {
    setShowModal: Dispatch<SetStateAction<any>>;
    setTokenIds: Dispatch<SetStateAction<any>>;
    tokenIds: number[];
}
const NftModal = ({setShowModal, tokenIds, setTokenIds}: ModalProps) => {

    const onModalClose = () => {
        setTokenIds([]);
        setShowModal(false);
    }

    return(
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Your Mints</h2>
                    <span className="close" onClick={() => onModalClose()}>&times;</span>
                </div>
                {tokenIds.length > 0 && tokenIds.map((token) => (
                    <div key={token} className="modal-body">
                        <div className="modal-body-left">
                            <h3>Token ID: {token.toString()}</h3>
                            <h3>Metadata Link: <a href={`https://gateway.pinata.cloud/ipfs/QmbE3Nq8JM1RbXgWK7n6JzCsMEStZVBGyc78zsQtStpjQZ/${token.toString()}.json`}>Pinata</a></h3>
                            <h3><a href={`https://paintswap.finance/marketplace/fantom/assets/0x1b60b6daa371f5066bd8c1dc032627bf1f4e95df/${token.toString()}`}>View on Paintswap</a></h3>
                            <h3><a href={`https://nftkey.app/collections/popskullys/token-details/?tokenId=${token.toString()}`}>View on NFTKey</a></h3>
                        </div>
                        <div className="modal-body-right">
                            <img className="token-img" src={`https://gateway.pinata.cloud/ipfs/QmeDHCCKorH241XsxPRmbNShYxkgh5o5UQcHtS3wxYBnH9/${token.toString()}.png`} alt=""/>
                        </div>
                    </div>
                ))}  
            </div>
        </div>
    )
}

export default NftModal;