import React from 'react';
import Store from './Store';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme
} from '@rainbow-me/rainbowkit';

import {
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';

import { publicProvider } from 'wagmi/providers/public';

import { fantom } from 'wagmi/chains';

//create provider
const { chains, provider } = configureChains(
  [
    fantom
  ],
  [
    publicProvider()
  ]
);

//connectors
const { connectors } = getDefaultWallets({
  chains,
  appName: 'Pop Skullys'
});

//create client
const wagmiClient = createClient({
  provider,
  connectors,
  autoConnect: true,
});


ReactDOM.render(
  <Store>
    <React.StrictMode>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
            chains={chains}
            theme={midnightTheme({
                accentColor: "white",
                accentColorForeground: "black",
                borderRadius: "none",
            })}
        >
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </React.StrictMode>
  </Store>,
  document.getElementById('root')
);
