import { ethers } from "ethers";
import { useState } from "react";
import { useAccount, useContractRead } from "wagmi";
import { POPSKULLY_ADDRESS } from "../constants";
import popsAbi from '../contractABIs/popsABI.json';

const useCheckWhitelist = () => {
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const { address } = useAccount();
    const { data, error } = useContractRead({
        address: POPSKULLY_ADDRESS,
        abi: popsAbi,
        functionName: 'whitelistedAddresses',
        args: [address],
        onSuccess() {
            console.log(data);
            if(Number(data) === 1) {
                setIsWhitelisted(true);
            }
        },
        onError() {
            console.log(error);
        }
    });
    console.log(isWhitelisted);
    return isWhitelisted;
}

export default useCheckWhitelist;